<template>
  <section class="page-container">
    <div class="flex-sb-center">
      <div
        class="header-title-back flex hand"
        @click="() => $router.back()"
      >
        <i class="el-icon-arrow-left"></i>
        <span>
          Back
        </span>
      </div>
      <div>
        <v-button
          class="button-info"
          @click="() => $router.back()">Cancel</v-button>
        <v-button
          class="button-theme"
          @click="submitForm">Save</v-button>
      </div>
    </div>
    <div class="flex-justify header-title-back mg-t-10">
      <h2>{{referralId?'Edit':'Create'}} Referrer</h2>
    </div>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >

      <v-title title="Referrer Information"></v-title>
      <el-row
        class="row-bg"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Agency Code</span>
                <el-form-item
                  ref="agency_code"
                  prop="agency_code"
                  :rules="rules.input">
                  <v-input
                    v-model='formData.agency_code'
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Company Name Eng</span>
                <el-form-item
                  ref="company_name_en"
                  prop="company_name_en">
                  <v-input
                    v-model='formData.company_name_en'
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Company Name Chi</span>
                <el-form-item
                  ref="company_name_chi"
                  prop="company_name_chi">
                  <v-input
                    v-model='formData.company_name_chi'
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Tel No</span>
                <el-form-item
                  ref="tel_no"
                  :rules="rules.tel"
                  prop="tel_no">
                  <v-input
                    v-model='formData.tel_no'
                    type="tel"
                    :maxlength="8"
                    prepend="+852"/>
                </el-form-item>
              </div>
            </v-row-col>

            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Fax No</span>
                <el-form-item
                  ref="fax_no"
                  prop="fax_no">
                  <v-input
                    v-model='formData.fax_no'
                    prepend="+852"/>
                </el-form-item>
              </div>
            </v-row-col>

            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Email</span>
                <el-form-item
                  ref="email"
                  :rules="rules.policy_holder_email"
                  prop="email">
                  <v-input
                    v-model='formData.email'
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Contact Person</span>
                <el-form-item
                  ref="contact_person"
                  prop="contact_person">
                  <v-input
                    v-model='formData.contact_person'
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Contact Number</span>
                <el-form-item
                  ref="contact_number"
                  :rules="rules.tel"
                  prop="contact_number">
                  <v-input
                    v-model='formData.contact_number'
                    :maxlength="8"
                    key-number
                    prepend="+852"
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Commission Rate</span>
                <el-form-item
                  ref="commission_rate"
                  prop="commission_rate">
                  <v-input
                    v-model='formData.commission_rate'
                    money
                    append="%"
                  />
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Account Status</span>
                <div class="car-type-radio">
                  <el-form-item
                    ref="account_status"
                    prop="account_status">
                    <v-radio-group
                      v-model="formData.account_status"
                      :border="true"
                      :list="yesNo2Options"
                    />
                  </el-form-item>
                </div>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Agency Last ModifiedDate</span>
                <el-form-item
                  ref="agency_last_modified_date"
                  prop="agency_last_modified_date">
                  <v-date-picker
                    v-model="formData.agency_last_modified_date"
                  />
                </el-form-item>
              </div>
            </v-row-col>
          </el-row>
        </v-row-col>
      </el-row>
      <v-title title="Login"></v-title>
      <el-row
        class="row-bg"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <!--            <v-row-col-->
            <!--              :same="true"-->
            <!--              :same-number="24">-->
            <!--              <div class="form-item ">-->
            <!--                <span>Account ID</span>-->
            <!--                <el-form-item-->
            <!--                  ref="agency_code"-->
            <!--                  prop="agency_code"-->
            <!--                  :rules="rules.input">-->
            <!--                  <v-input-->
            <!--                    v-model='formData.agency_code'-->
            <!--                  />-->
            <!--                </el-form-item>-->
            <!--              </div>-->
            <!--            </v-row-col>-->
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Login Password</span>
                <el-form-item
                  ref="password"
                  :rules="referralId?'':rules.input"
                  prop="password">
                  <v-input
                    v-model='formData.password'
                    maxlength="8"
                    key-non-chinese
                  />
                </el-form-item>
              </div>
            </v-row-col>
          </el-row>
        </v-row-col>
      </el-row>
      <v-title title="Address"></v-title>
      <div class="address-content">
        <div
          v-for="(item,index) in formData.address"
          :key="index"
          class="card mg-b-30">
          <address-form
            :ref="`address-form${index}`"
            :formType="1"
            :index="index"
            :title="`Address${index+1}`"
            not-valid
            :form="item"
            @getFormData="getSubFormData"/>
        </div>
      </div>

    </el-form>
    <div class="bottom-button">
      <v-button
        class="button-theme"
        @click="submitForm">Save</v-button>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import AddressForm from '@/views/components/address-form'
import {createReferrer, getReferrerDetail, editReferrer} from '@api/referrer'
export default {
  name: 'referralCreate',
  components: {
    AddressForm,
    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
  },
  data(){
    return{
      formData:{
        agency_code:'',
        company_name_en:'',
        company_name_chi:'',
        tel_no:'',
        fax_no:'',
        password:'',
        eamil:'',
        commission_rate:'',
        account_status:'',
        contact_person:'',
        contact_number:'',
        agency_last_modified_date:'',
        address:[
          {
            room: '',
            floor: '',
            block: '',
            building: '',
            street: '',
            district: '',
            area: '',
            region: 1
          },
          // {
          //   room: '',
          //   floor: '',
          //   block: '',
          //   building: '',
          //   street: '',
          //   district: '',
          //   area: '',
          //   region: 1
          // },
          // {
          //   room: '',
          //   floor: '',
          //   block: '',
          //   building: '',
          //   street: '',
          //   district: '',
          //   area: '',
          //   region: 1
          // },
          // {
          //   room: '',
          //   floor: '',
          //   block: '',
          //   building: '',
          //   street: '',
          //   district: '',
          //   area: '',
          //   region: 1
          // }
        ],
      },
      subForm: {
        'address-form0': false,
        // 'address-form1': false,
        // 'address-form2': false,
        // 'address-form3': false,
      },
    }
  },
  computed:{
    referralId(){
      let {id} = this.$route.params
      return id
    }
  },
  mounted(){
    let {id} = this.$route.params
    if(id){
      this.network().getReferrerDetail()
    }
  },
  methods:{
    getSubFormData(form, data) {
      this.subForm[form] = true
      let {correspondence_address,index} =data
      this.formData.address[index]  = correspondence_address
      // this.formData = Object.assign(this.formData, data)
    },
    submitForm(formName,is_draft) {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key][0].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          if(this.referralId)
            this.network().editReferrer()
          else
            this.network().createReferrer()
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    network() {
      return {
        createReferrer: async (params) => {
          const { data } = await createReferrer({
            ...this.formData,
            ...params
          })
          this.updateGlobalOptions()
          this.$router.back()
        },
        editReferrer: async (params) => {
          const { data } = await editReferrer({
            ...this.formData,
            ...params,
            referral_id:this.referralId
          })
          this.updateGlobalOptions()
          this.$router.back()
        },
        getReferrerDetail: async (params) => {
          const { data } = await getReferrerDetail({
            referral_id:this.referralId
          })
          Object.keys(this.formData).some(key => {
            if (key in data) {
              let {address=[]} = data
              if(key=='address'){
                this.formData.address = address.length>0?address:this.formData.address
              }else
                this.formData[key] = data[key]
            }
          })

        },
      }
    },
  }
}
</script>

<style scoped lang="scss">
.page-container{
.header-title-back{
  .mg-t-10{
    margin-top: 10px;
  }
}
  .address-content{
    max-width: 770px;
  }
  .mg-b-30{
    margin-bottom: 30px;
  }
  .bottom-button{
    max-width: 770px;
    width: 100%;
    text-align: right;
  }
}
</style>
